import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => {
  const { name = '', className = '', fontSize = '16px', style, ...rest } = props

  return (
    <i
      {...rest}
      className={`fa fa-${name} ${className}`}
      style={{ ...style, fontSize: fontSize }}
    />
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
}

Icon.defaultProps = {
  fontSize: 'inherit',
}

export default Icon
